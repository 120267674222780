import { DOMHelper } from "../Helpers/DOMHelper.js";
import { RequestHelper } from '../Helpers/RequestHelper.js';

export class ButtonApp {
	
	constructor(pmchat, config){		
		let app = this;
		app.pmchat = pmchat;
		//CONFIG		
		this.config = this.defaultConfig();		
		Object.assign(this.config, config);
		
		//ATTS
		this.pmcbCaptchaId = null;
		this.wrapperClass = 'pm-chat-button-app';
		this.appId = 'pmcb_' + Date.now() + '_' + Math.random().toString(36).substr(2, 9);
        
		this.state = {
			currentPage: 0,
			selectedDepartment: null
		}

		//init
		this.init();

	}


	defaultConfig(){
		return {
			recaptchaSiteKey: '6Lc6cyQkAAAAAH4x37pQL69AsErQf3WY3JyFw6Qi',
		};
	}

	init(){
		this.addHtml();
        this.addHandlers();
		this.setRecaptchaKeys();
		this.setInitialValues();
	}	

	setInitialValues(){
		if(this.pmchat.dealerSettings){
			let apiFromNumber = this.pmchat.dealerSettings.api_from_number;
			DOMHelper.setInnerHTML(this.getSelector('.api-from-number-value'), apiFromNumber);
		}
		let dealerName = location.hostname;
		if('pm_datalayer_props' in window){
			dealerName = pm_datalayer_props.dealerName || dealerName;
		}
		DOMHelper.setInnerHTML(this.getSelector('.dealername-value'), dealerName);

	}

	addHtml(){
		var classes = [
			this.wrapperClass
		].join(" ");

		document.querySelector("body")
			.insertAdjacentHTML('beforeend',`<div class="` + classes +`" id="`+ this.appId +`"><div class="pmcb-app">    
    <div class="pmcb-panel">
        <form class="pmcb-msg-form" action="">
            <input type="hidden" name="pmTwilioSend" value="1">
            <input type="hidden" name="ajax" value="1">
            <input type="hidden" name="source" value="pm-button-app">
            <!-- page 0 -->
            <div class="pmcb-panel-page active" data-page="0">
                <div class="pmcb-panel-title"><i class="pmc-fa pmc-fa-user"></i> &nbsp; Choose a Department <span class="pmcb-x-btn">x</span></div>
                <div class="pmcb-panel-body">
                    <div class="pmcb-body-section">
                        <div class="pmcb-radio-option">
                            <input type="radio" name="department" value="sales">
                            Sales
                        </div>
                        <div class="pmcb-radio-option">
                            <input type="radio" name="department" value="service">
                            Service
                        </div>
                        <div class="pmcb-radio-option">
                            <input type="radio" name="department" value="parts">
                            Parts
                        </div>
                    </div>
                </div>
            </div>
            <!-- page 1 -->
            <div class="pmcb-panel-page" data-page="1">
                <div class="pmcb-panel-title">
                    <i class="pmc-fa pmc-fa-arrow-left pmcb-panel-back-btn"></i> 
                    &nbsp;Send a text to <span class="department-value">department</span> 
                    <span class="pmcb-x-btn">x</span>
                </div>
                <div class="pmcb-panel-body">
                    <p style="margin-top: 0;">Add your details and a short message, we'll respond with a text</p>
                    <div class="pmcb-body-section">
                        <div class="pmc-input">
    <label>First and Last Name</label>
    <input type="text" name="pmFirstField" required>
    <span class="input-error"></span> 
</div>

                        <div class="pmc-input">
                            <label>Mobile Number</label>
                            <input type="text" name="pmPhoneField"                             
                            maxlength="14" 
                            pattern="\\(\\d{3}\\) \\d{3}-\\d{4}"
                            required>
                            <span class="input-error"></span> 
                        </div>

                        <div class="pmc-input">
    <label>Message</label>
    <input type="text" name="pmMessageField" required>
    <span class="input-error"></span> 
</div>
                    </div>
                    <div class="g-recaptcha" data-sitekey="" id="pmcb_captcha"></div>
                    <p class="pmcb-msg-form-error"></p>
                    <button class="pmcb-button" type="submit">Send</button>
                    <p class="pmcb-disclaimer">
                        By clicking “Send,” you give permission for <span class="dealername-value"></span> to send text messages and marketing materials to the mobile number you've provided, which may include automated technology. Your consent is not required for purchase. Standard message and data rates may apply. To opt out at any time, text STOP.
                    </p>
                </div>
            </div>
            <!-- page 2 -->
            <div class="pmcb-panel-page" data-page="2">
                <div class="pmcb-panel-title"><i class="pmc-fa pmc-fa-check"></i> &nbsp; Text Message Sent <span class="pmcb-x-btn">x</span></div>
                <div class="pmcb-panel-body" style="background-color: white;">
                    <p class="chat-message msg-value"></p>
                    <p class="chat-message dealer-message">
                        We've received you message!
                        You'll receive a text message back from the following number: <span class="api-from-number-value"></span>
                    </p>
                    <p class="chat-message dealer-message">Thank you!</p>
                </div>
            </div>
        </form>
    </div>
    <div>
        <button class="pmcb-floating-btn show"> <i class="pmc-fa pmc-fa-envelope"></i> Text Us !</button>
        <button class="pmcb-floating-btn no-mobile"> <i class="pmc-fa pmc-fa-x"></i> Close</button>
    </div>    
</div></div>`);		        

		//SPOT FOR MODALS HTML
        // document
        //     .querySelector("body")
        //     .insertAdjacentHTML(
        //         "beforeend",
        //         "<div class='" + classes + " pm-chat-modals'></div>"
        //     );
	}

	setRecaptchaKeys(){		
	    if(typeof grecaptcha === 'undefined') {
	    	console.error('Google recaptcha missing');
	    	return;
		}

		var $this = this;
		let selector = $this.getSelector(".g-recaptcha");
		DOMHelper.querySelectorAction(selector, (item) => {			
			$this.pmcbCaptchaId = grecaptcha.render(item, {
	          'sitekey' : $this.config.recaptchaSiteKey	          
	        });
		});
	}

	setPageActive(page){
		DOMHelper.removeClass(this.getSelector('.pmcb-panel-page'), 'active');			
		DOMHelper.addClass(this.getSelector(".pmcb-panel-page[data-page='"+ page +"']"), 'active');
		this.state.currentPage = page;
	}

    addHandlers() {
        var $this = this;
		
		DOMHelper.addHandler($this.getSelector("input[name=pmPhoneField]"), "input", (event) => {
			let input = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
			if (input.length > 3 && input.length <= 6) {
				input = `(${input.slice(0, 3)}) ${input.slice(3)}`;
			} else if (input.length > 6) {
				input = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
			}
			event.target.value = input;
		});

        DOMHelper.addHandler($this.getSelector(".pmcb-floating-btn")
		+ ", " + $this.getSelector(".pmcb-x-btn"), "click", function(){
            DOMHelper.toggleClass($this.getSelector(".pmcb-floating-btn"), 'show');
            DOMHelper.toggleClass($this.getSelector(".pmcb-panel"), 'show');
        });        

		DOMHelper.addHandler($this.getSelector(".pmcb-radio-option"), "click", function(e){
			let $currentPage = document.querySelector($this.getSelector(".pmcb-panel-page.active"));
			let nextPage = Number($currentPage.dataset.page) + 1;
            $this.setPageActive(nextPage);
			let radioInput = e.target.querySelector('input');
			radioInput.checked=true;
			$this.state.selectedDepartment = radioInput.value;
			DOMHelper.setInnerHTML($this.getSelector('.department-value'), $this.state.selectedDepartment);			
        });
		
		DOMHelper.addHandler($this.getSelector(".pmcb-panel-back-btn"), "click", function(){
            $this.setPageActive($this.state.currentPage-1);
        }); 

		DOMHelper.addHandler($this.getSelector('.pmcb-msg-form'),'submit',(e) => {
			e.preventDefault();
			//submit
			// FRONT CAPTCHA CHECK
			var captchaResponse = grecaptcha.getResponse($this.pmcbCaptchaId);//id comes from render 
			if (captchaResponse.length === 0) {
				$this.showFormErrorMsg("Please complete the captcha");
				return false;
			}			
			const data = new FormData(e.target);        
			// print data
			for (let [key, value] of data.entries()) {
				console.log(key + ': ' + value);
			}
			var submitBtn = e.target.querySelector('button[type=submit]')
			submitBtn.disabled = true;
			//ajax
			this.pmchat.pmDealersite.sendTwilioMsg(
				data,
				(response) => {
					console.log(response);
					let msg = document.querySelector($this.getSelector("input[name='pmMessageField']")).value;
					DOMHelper.setInnerHTML($this.getSelector('.msg-value'), msg);					
					$this.setPageActive(2);			
				},
				(error) => {                                
					console.error(error);                
					submitBtn.disabled = false;
				}
			);
		});		
    }	

	showFormErrorMsg(msg){
        var selector = this.getSelector(".pmcb-msg-form-error");
        DOMHelper.setInnerHTML(selector, msg);

        setTimeout(() => {
            DOMHelper.setInnerHTML(selector, "");
        }, 4000);
    }

	getSelector(selector){
        return "." + this.wrapperClass + "#" + this.appId + " " + selector;		
	}
}
